@import "~foundation-sites/scss/util/util";
@import "../../resources/scss/settings";


$menu-timing: (
    small: 500ms,
    medium: 750ms,
    large: 1000ms,
);

.menu-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: lighten(map_get($foundation-palette, primary), 60%);
  z-index: 9;
  transition: transform 1000ms ease;

  transition-duration: map_get($menu-timing, small);
  transition-delay: map_get($menu-timing, small) / 2;

  @include breakpoint(medium) {
    transition-duration: map_get($menu-timing, medium);
    transition-delay: map_get($menu-timing, medium) / 2;
  }
  @include breakpoint(large) {
    transition-duration: map_get($menu-timing, large);
    transition-delay: map_get($menu-timing, large) / 2;
  }



  &:before {
    content: "\00a0";
    position: inherit;
    width: inherit;
    height: inherit;
    background: map_get($foundation-palette, primary);
    transform-origin: top left;
    transform: scaleX(1)  translateX(100%);
    transition: inherit;
    transition-duration: map_get($menu-timing, small) * .75;

    @include breakpoint(medium) {
      transition-duration: map_get($menu-timing, medium) * .75;
    }
    @include breakpoint(large) {
      transition-duration: map_get($menu-timing, large) * .75;
    }
  }

  .menu-container {
    text-align: center;
    //padding-top: 25px;

    h1 {
      font-size: 54px;
    }

    ul {
      list-style: none;
      margin: 0;
      display: grid;
      grid-template-rows: repeat(3, calc((100vh - 200px) / 3));
      align-items: center;
      margin-top: -20px;

      li {
        button {
          display: inline;
          color: darken(map_get($foundation-palette, primary), 1%);
          font-size: 26px;
        }
      }
    }
  }

  &:not(.show) {
    transform: translateX(-100%);
    transition-timing-function: ease-in;
    transition-duration: map_get($menu-timing, small) / 2;

    @include breakpoint(medium) {
      transition-duration: map_get($menu-timing, medium) / 2;
    }
    @include breakpoint(large) {
      transition-duration: map_get($menu-timing, large) / 2;
    }

    &:before {
      transform: scaleX(0);
      transition-timing-function: ease-out;
      transition-delay: map_get($menu-timing, small);
      transition-duration: map_get($menu-timing, small) * 1.5;

      @include breakpoint(medium) {
        transition-delay: map_get($menu-timing, medium);
        transition-duration: map_get($menu-timing, medium) * 1.5;
      }
      @include breakpoint(large) {
        transition-delay: map_get($menu-timing, large);
        transition-duration: map_get($menu-timing, large) * 1.5;
      }
    }
  }
}