@import "~foundation-sites/scss/util/util";

.splash-wrapper {
  height: 100vh;
  min-height: 400px;
  position: relative;



  &.moved {
    > .splash {
      margin-top: -0vh;

      @include breakpoint(xlarge) {
        margin-top: -10vh;
      }
      @include breakpoint(xxlarge) {
        margin-top: -20vh;
      }
    }
  }

  &.small {
    height: 20vh;
    min-height: 300px;

    .sub-title {
      display: none;
    }
  }

  .splash {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    transform-style: preserve-3d;
    transition: transform 1s ease, margin-top 1s ease;

    h1 {
      span {
        display: block;

        &:nth-child(1) {
          font-size: 36px;
        }

        &:nth-child(2) {
          font-size: 16px;
        }

        &:nth-child(3) {
          font-size: 72px;
          margin-top: -4px;
        }
      }
    }
  }
}