@import "settings";

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}
body {
  margin: 0;
  background: $body-background;

  .intro {
    margin-left: auto;
    margin-right: auto;

    &:not(.full) {
      width: 231.7px;
      max-width: 80vw;
    }

    h1 {
      white-space: nowrap;
    }
  }
  .small-text {
    font-size: 11px;
  }
}
*:focus {
  outline: 0;
}