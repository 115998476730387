@import "~foundation-sites/scss/util/util";
@import "../../resources/scss/settings";

.footer-wrapper {
  background: darken(map_get($foundation-palette, primary), 41%);
  min-height: 200px;
  position: relative;
  margin-top: 110px;
  color: $white;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  grid-row-gap: 25px;
  padding: 100px 20px 25px;
  justify-items: center;

  @include breakpoint(medium) {
    padding: 125px 50px 25px;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
  }

  &:before {
    background: $black;
    content: "\00a0";
    width: 100vw;
    min-width: 1440px;
    left: 50%;
    height: 50px;
    position: absolute;
    z-index: 0;
    top: -1px;
    clip-path: polygon(100% 100%, 100% 0, 0 0);
    transform: translateX(-50%);
  }

  div {
    width: 200px;

    h3 {
      font-size: 20px;
    }

    ul {
      list-style: none;
      margin: 0;
      font-size: 14px;
      opacity: 0.8;

      li {
        a {
          color: $white;
        }
      }
    }
  }
}