@import "~foundation-sites/scss/util/util";
@import "../../resources/scss/settings";

.portfolio-wrapper {
  transition: margin-top 2000ms ease;
  margin-top: 25px;
  position: relative;

  > .content-wrapper {
    transition: max-height 2500ms ease 500ms;
    max-height: 100px;
    overflow: hidden;
    min-height: auto !important;
  }


  &.visible {
    margin-top: - 25vw;

    > .content-wrapper {
      max-height: 250vh;
    }
  }

  &:before, &:after {
    background: $white;
    content: "\00a0";
    width: 100vw;
    min-width: 1440px;
    left: 50%;
    height: 50px;
    position: absolute;
    z-index: -1;
  }

  &:before {
    transform: translateY(-100%) translateX(-50%);
    clip-path: polygon(100% 100%, 0 100%, 0 0);
  }

  &:after {
    bottom: 0;
    transform: translateY(100%) translateX(-50%);
    clip-path: polygon(100% 100%, 100% 0, 0 0);
  }
}