@import "../../resources/scss/settings";

.contact-wrapper {
  position: relative;
  width: 90vw;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);

  .intro {
    transition: all 1s ease;
    min-height: 0;

    .loading-wrapper, .done-wrapper, .form-wrapper {
      overflow: hidden;
      max-height: 0;
      transition: inherit;
      transition-delay: 1s;
    }
    .form-wrapper {
      transition-delay: 0s;
    }
    .loading-wrapper, .done-wrapper {
      position: absolute;
      top: 0;
    }
    .loading-wrapper {
      .loading-indicator {
        position: relative;
        height: 30px;
        width: 100%;

        &:before {
          position: absolute;
          content: "\00a0";
          border-radius: 10px;
          width: 100px;
          height: 10px;
          transform-origin: center center;
          border: solid 10px map_get($foundation-palette, primary);
          animation-name: move-around;
          animation-duration: 1.5s;
          animation-fill-mode: both;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          animation-direction: alternate-reverse;

          @keyframes move-around {
            0% {
              transform: translateX(100%);
              width: 10px;
              right: 100%;
            }
            50% {
              transform: translateX(50%);
              width: 100px;
            }
            100% {
              transform: translateX(0);
              width: 10px;
              right: 0;
            }
          }
        }
      }
    }
    &:not(.loading):not(.done) {
      .form-wrapper {
        max-height: 533px;
      }
    }
    &.loading, &.done {
      min-height: 100px;
    }
    &.loading {
      .form-wrapper {
        max-height: 0;
      }
      .loading-wrapper {
        max-height: 200px;
      }
    }
    &.done {
      .loading-wrapper {
        transition-delay: 0s;
      }
      .done-wrapper {
        max-height: 200px;
      }
    }
  }
}