@import "../../../resources/scss/settings";

.menu-button-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  padding: 5px;
  z-index: 10;
  overflow: hidden;

  .menu-button {
    position: relative;
    height: 30px;
    width: 30px;
    display: inline-block;

    span {
      position: absolute;
      display: block;
      height: 12.5%;
      width: 100%;
      background: map_get($foundation-palette, primary);
      transition: all 1000ms ease;

      &:before {
        content: "\00a0";
      }
      &:nth-child(1) {
        top: 0;
        transform-origin: top left;
      }
      &:nth-child(2), &:nth-child(3) {
        top: calc(50% - 6.25%);
        height: 12.5% / 2;
      }
      &:nth-child(4) {
        bottom: 0;
        transform-origin: top right;
      }
    }
  }

  &.open {
    .menu-button {
      span {
        &:nth-child(1) {
          transform: scaleX(0);
        }
        &:nth-child(2) {
          top: calc(50% - 6.25%);
          transform: rotate(-45deg);
        }
        &:nth-child(3) {
          bottom: calc(50% - 6.25%);
          transform: rotate(45deg);
        }
        &:nth-child(4) {
          transform: scaleX(0);
        }
      }
    }
  }
}