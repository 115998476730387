@import url(https://fonts.googleapis.com/css?family=Heebo:300,400,700&display=swap);
@charset "UTF-8";
body {
  font-family: 'Heebo', sans-serif;
  color: #373737;
  font-size: 16px; }

@font-face {
  font-family: 'The Bold Font';
  src: url(/static/media/theboldfont.a1e62992.ttf) format("truetype");
  font-display: swap; }

.bold-font, .bold-font * {
  font-family: 'The Bold Font', "Arial Black", sans-serif;
  font-weight: bold; }

.primary {
  color: #ef7400; }

.secondary {
  color: #373737; }

.white {
  color: white; }

/**
 * Foundation for Sites by ZURB
 * Version 6.5.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column, .columns, .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: left;
    clear: none; }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  margin-right: 0;
  margin-left: 0; }
  .small-uncentered, .small-uncentered:last-child:not(:first-child),
  .small-push-0,
  .small-push-0:last-child:not(:first-child),
  .small-pull-0,
  .small-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none; }
  .small-uncentered:last-child:not(:first-child),
  .small-push-0:last-child:not(:first-child),
  .small-pull-0:last-child:not(:first-child) {
    float: right; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0; }
    .medium-uncentered, .medium-uncentered:last-child:not(:first-child),
    .medium-push-0,
    .medium-push-0:last-child:not(:first-child),
    .medium-pull-0,
    .medium-pull-0:last-child:not(:first-child) {
      float: left;
      clear: none; }
    .medium-uncentered:last-child:not(:first-child),
    .medium-push-0:last-child:not(:first-child),
    .medium-pull-0:last-child:not(:first-child) {
      float: right; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0; }
    .large-uncentered, .large-uncentered:last-child:not(:first-child),
    .large-push-0,
    .large-push-0:last-child:not(:first-child),
    .large-pull-0,
    .large-pull-0:last-child:not(:first-child) {
      float: left;
      clear: none; }
    .large-uncentered:last-child:not(:first-child),
    .large-push-0:last-child:not(:first-child),
    .large-pull-0:last-child:not(:first-child) {
      float: right; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.99875em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.99875em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #ef7400;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #ce6400; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #373737;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #373737; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #373737; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

*, *:before, *:after {
  box-sizing: border-box; }

html, body {
  overflow-x: hidden; }

body {
  margin: 0;
  background: #373737; }
  body .intro {
    margin-left: auto;
    margin-right: auto; }
    body .intro:not(.full) {
      width: 231.7px;
      max-width: 80vw; }
    body .intro h1 {
      white-space: nowrap; }
  body .small-text {
    font-size: 11px; }

*:focus {
  outline: 0; }

button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer; }
  button:focus {
    outline: 0; }
  button:not(.button) {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center; }
    button:not(.button) > * {
      display: inline-block;
      margin: 0 auto; }

.button {
  line-height: 37px;
  padding: 0 20px;
  font-weight: 700;
  background: none;
  border: none;
  font-size: 18px;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer; }
  .button.hollow {
    border-radius: 2px;
    border: solid 3px; }
    .button.hollow.white {
      border-color: white;
      color: white; }
    .button.hollow.primary {
      border-color: #ef7400;
      color: #ef7400; }
    .button.hollow.secondary {
      border-color: #373737;
      color: #373737; }
  .button:not(.hollow).primary {
    background-color: #ef7400;
    color: white; }


.splash-wrapper {
  height: 100vh;
  min-height: 400px;
  position: relative; }
  .splash-wrapper.moved > .splash {
    margin-top: -0vh; }
    @media screen and (min-width: 75em) {
      .splash-wrapper.moved > .splash {
        margin-top: -10vh; } }
    @media screen and (min-width: 90em) {
      .splash-wrapper.moved > .splash {
        margin-top: -20vh; } }
  .splash-wrapper.small {
    height: 20vh;
    min-height: 300px; }
    .splash-wrapper.small .sub-title {
      display: none; }
  .splash-wrapper .splash {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transition: margin-top 1s ease, -webkit-transform 1s ease;
    transition: margin-top 1s ease, -webkit-transform 1s ease;
    transition: transform 1s ease, margin-top 1s ease;
    transition: transform 1s ease, margin-top 1s ease, -webkit-transform 1s ease; }
    .splash-wrapper .splash h1 span {
      display: block; }
      .splash-wrapper .splash h1 span:nth-child(1) {
        font-size: 36px; }
      .splash-wrapper .splash h1 span:nth-child(2) {
        font-size: 16px; }
      .splash-wrapper .splash h1 span:nth-child(3) {
        font-size: 72px;
        margin-top: -4px; }

.sub-title {
  white-space: nowrap;
  font-size: 20px; }
  .sub-title span.we {
    height: 30px;
    display: inline-block; }
  .sub-title span {
    line-height: inherit; }
  .sub-title ul {
    display: block;
    position: relative;
    list-style: none;
    line-height: inherit;
    margin-left: 34px;
    margin-top: -30px; }
    .sub-title ul li {
      position: absolute;
      left: 0;
      top: 0;
      white-space: nowrap;
      -webkit-transition: all 250ms ease;
      transition: all 250ms ease;
      -webkit-clip-path: inset(0 0 0 0);
              clip-path: inset(0 0 0 0); }
      .sub-title ul li:not(.active) {
        -webkit-clip-path: inset(0 100% 0 0);
                clip-path: inset(0 100% 0 0); }
      .sub-title ul li.active {
        -webkit-transition-delay: 500ms;
                transition-delay: 500ms; }

.portfolio-wrapper {
  -webkit-transition: margin-top 2000ms ease;
  transition: margin-top 2000ms ease;
  margin-top: 25px;
  position: relative; }
  .portfolio-wrapper > .content-wrapper {
    -webkit-transition: max-height 2500ms ease 500ms;
    transition: max-height 2500ms ease 500ms;
    max-height: 100px;
    overflow: hidden;
    min-height: auto !important; }
  .portfolio-wrapper.visible {
    margin-top: -25vw; }
    .portfolio-wrapper.visible > .content-wrapper {
      max-height: 250vh; }
  .portfolio-wrapper:before, .portfolio-wrapper:after {
    background: white;
    content: "\00a0";
    width: 100vw;
    min-width: 1440px;
    left: 50%;
    height: 50px;
    position: absolute;
    z-index: -1; }
  .portfolio-wrapper:before {
    -webkit-transform: translateY(-100%) translateX(-50%);
            transform: translateY(-100%) translateX(-50%);
    -webkit-clip-path: polygon(100% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 100%, 0 100%, 0 0); }
  .portfolio-wrapper:after {
    bottom: 0;
    -webkit-transform: translateY(100%) translateX(-50%);
            transform: translateY(100%) translateX(-50%);
    -webkit-clip-path: polygon(100% 100%, 100% 0, 0 0);
            clip-path: polygon(100% 100%, 100% 0, 0 0); }

.projects-wrapper {
  position: relative;
  display: grid; }
  @media print, screen and (min-width: 64em) {
    .projects-wrapper {
      display: inherit; } }
  .projects-wrapper[data-length="1"] {
    grid-template-columns: repeat(1, 300vw); }
  .projects-wrapper[data-length="2"] {
    grid-template-columns: repeat(2, 300vw); }
  .projects-wrapper[data-length="3"] {
    grid-template-columns: repeat(3, 300vw); }
  .projects-wrapper[data-length="4"] {
    grid-template-columns: repeat(4, 300vw); }
  .projects-wrapper[data-length="5"] {
    grid-template-columns: repeat(5, 300vw); }
  .projects-wrapper[data-length="6"] {
    grid-template-columns: repeat(6, 300vw); }
  .projects-wrapper[data-length="7"] {
    grid-template-columns: repeat(7, 300vw); }
  .projects-wrapper[data-length="8"] {
    grid-template-columns: repeat(8, 300vw); }
  .projects-wrapper[data-length="9"] {
    grid-template-columns: repeat(9, 300vw); }
  .projects-wrapper[data-length="10"] {
    grid-template-columns: repeat(10, 300vw); }
  .projects-wrapper[data-length="11"] {
    grid-template-columns: repeat(11, 300vw); }
  .projects-wrapper[data-length="12"] {
    grid-template-columns: repeat(12, 300vw); }
  .projects-wrapper[data-length="13"] {
    grid-template-columns: repeat(13, 300vw); }
  .projects-wrapper[data-length="14"] {
    grid-template-columns: repeat(14, 300vw); }
  .projects-wrapper[data-length="15"] {
    grid-template-columns: repeat(15, 300vw); }
  .projects-wrapper[data-length="16"] {
    grid-template-columns: repeat(16, 300vw); }
  .projects-wrapper[data-length="17"] {
    grid-template-columns: repeat(17, 300vw); }
  .projects-wrapper[data-length="18"] {
    grid-template-columns: repeat(18, 300vw); }
  .projects-wrapper[data-length="19"] {
    grid-template-columns: repeat(19, 300vw); }
  .projects-wrapper[data-length="20"] {
    grid-template-columns: repeat(20, 300vw); }
  .projects-wrapper[data-length="21"] {
    grid-template-columns: repeat(21, 300vw); }
  .projects-wrapper[data-length="22"] {
    grid-template-columns: repeat(22, 300vw); }
  .projects-wrapper[data-length="23"] {
    grid-template-columns: repeat(23, 300vw); }
  .projects-wrapper[data-length="24"] {
    grid-template-columns: repeat(24, 300vw); }
  .projects-wrapper[data-length="25"] {
    grid-template-columns: repeat(25, 300vw); }
  .projects-wrapper[data-length="26"] {
    grid-template-columns: repeat(26, 300vw); }
  .projects-wrapper[data-length="27"] {
    grid-template-columns: repeat(27, 300vw); }
  .projects-wrapper[data-length="28"] {
    grid-template-columns: repeat(28, 300vw); }
  .projects-wrapper[data-length="29"] {
    grid-template-columns: repeat(29, 300vw); }
  .projects-wrapper[data-length="30"] {
    grid-template-columns: repeat(30, 300vw); }
  .projects-wrapper[data-length="31"] {
    grid-template-columns: repeat(31, 300vw); }
  .projects-wrapper[data-length="32"] {
    grid-template-columns: repeat(32, 300vw); }
  .projects-wrapper[data-length="33"] {
    grid-template-columns: repeat(33, 300vw); }
  .projects-wrapper[data-length="34"] {
    grid-template-columns: repeat(34, 300vw); }
  .projects-wrapper[data-length="35"] {
    grid-template-columns: repeat(35, 300vw); }
  .projects-wrapper[data-length="36"] {
    grid-template-columns: repeat(36, 300vw); }
  .projects-wrapper[data-length="37"] {
    grid-template-columns: repeat(37, 300vw); }
  .projects-wrapper[data-length="38"] {
    grid-template-columns: repeat(38, 300vw); }
  .projects-wrapper[data-length="39"] {
    grid-template-columns: repeat(39, 300vw); }
  .projects-wrapper[data-length="40"] {
    grid-template-columns: repeat(40, 300vw); }
  .projects-wrapper[data-length="41"] {
    grid-template-columns: repeat(41, 300vw); }
  .projects-wrapper[data-length="42"] {
    grid-template-columns: repeat(42, 300vw); }
  .projects-wrapper[data-length="43"] {
    grid-template-columns: repeat(43, 300vw); }
  .projects-wrapper[data-length="44"] {
    grid-template-columns: repeat(44, 300vw); }
  .projects-wrapper[data-length="45"] {
    grid-template-columns: repeat(45, 300vw); }
  .projects-wrapper[data-length="46"] {
    grid-template-columns: repeat(46, 300vw); }
  .projects-wrapper[data-length="47"] {
    grid-template-columns: repeat(47, 300vw); }
  .projects-wrapper[data-length="48"] {
    grid-template-columns: repeat(48, 300vw); }
  .projects-wrapper[data-length="49"] {
    grid-template-columns: repeat(49, 300vw); }
  .projects-wrapper[data-length="50"] {
    grid-template-columns: repeat(50, 300vw); }
  .projects-wrapper[data-length="51"] {
    grid-template-columns: repeat(51, 300vw); }
  .projects-wrapper[data-length="52"] {
    grid-template-columns: repeat(52, 300vw); }
  .projects-wrapper[data-length="53"] {
    grid-template-columns: repeat(53, 300vw); }
  .projects-wrapper[data-length="54"] {
    grid-template-columns: repeat(54, 300vw); }
  .projects-wrapper[data-length="55"] {
    grid-template-columns: repeat(55, 300vw); }
  .projects-wrapper[data-length="56"] {
    grid-template-columns: repeat(56, 300vw); }
  .projects-wrapper[data-length="57"] {
    grid-template-columns: repeat(57, 300vw); }
  .projects-wrapper[data-length="58"] {
    grid-template-columns: repeat(58, 300vw); }
  .projects-wrapper[data-length="59"] {
    grid-template-columns: repeat(59, 300vw); }
  .projects-wrapper[data-length="60"] {
    grid-template-columns: repeat(60, 300vw); }
  @media screen and (max-width: 63.99875em) {
    .projects-wrapper:not(.is-dragging) {
      -webkit-transition: -webkit-transform 250ms ease;
      transition: -webkit-transform 250ms ease;
      transition: transform 250ms ease;
      transition: transform 250ms ease, -webkit-transform 250ms ease; }
    .projects-wrapper[data-active-slide="1"] {
      -webkit-transform: translateX(calc(100vw * -1));
              transform: translateX(calc(100vw * -1)); }
    .projects-wrapper[data-active-slide="2"] {
      -webkit-transform: translateX(calc(100vw * -2));
              transform: translateX(calc(100vw * -2)); }
    .projects-wrapper[data-active-slide="3"] {
      -webkit-transform: translateX(calc(100vw * -3));
              transform: translateX(calc(100vw * -3)); }
    .projects-wrapper[data-active-slide="4"] {
      -webkit-transform: translateX(calc(100vw * -4));
              transform: translateX(calc(100vw * -4)); }
    .projects-wrapper[data-active-slide="5"] {
      -webkit-transform: translateX(calc(100vw * -5));
              transform: translateX(calc(100vw * -5)); }
    .projects-wrapper[data-active-slide="6"] {
      -webkit-transform: translateX(calc(100vw * -6));
              transform: translateX(calc(100vw * -6)); }
    .projects-wrapper[data-active-slide="7"] {
      -webkit-transform: translateX(calc(100vw * -7));
              transform: translateX(calc(100vw * -7)); }
    .projects-wrapper[data-active-slide="8"] {
      -webkit-transform: translateX(calc(100vw * -8));
              transform: translateX(calc(100vw * -8)); }
    .projects-wrapper[data-active-slide="9"] {
      -webkit-transform: translateX(calc(100vw * -9));
              transform: translateX(calc(100vw * -9)); }
    .projects-wrapper[data-active-slide="10"] {
      -webkit-transform: translateX(calc(100vw * -10));
              transform: translateX(calc(100vw * -10)); }
    .projects-wrapper[data-active-slide="11"] {
      -webkit-transform: translateX(calc(100vw * -11));
              transform: translateX(calc(100vw * -11)); }
    .projects-wrapper[data-active-slide="12"] {
      -webkit-transform: translateX(calc(100vw * -12));
              transform: translateX(calc(100vw * -12)); }
    .projects-wrapper[data-active-slide="13"] {
      -webkit-transform: translateX(calc(100vw * -13));
              transform: translateX(calc(100vw * -13)); }
    .projects-wrapper[data-active-slide="14"] {
      -webkit-transform: translateX(calc(100vw * -14));
              transform: translateX(calc(100vw * -14)); }
    .projects-wrapper[data-active-slide="15"] {
      -webkit-transform: translateX(calc(100vw * -15));
              transform: translateX(calc(100vw * -15)); }
    .projects-wrapper[data-active-slide="16"] {
      -webkit-transform: translateX(calc(100vw * -16));
              transform: translateX(calc(100vw * -16)); }
    .projects-wrapper[data-active-slide="17"] {
      -webkit-transform: translateX(calc(100vw * -17));
              transform: translateX(calc(100vw * -17)); }
    .projects-wrapper[data-active-slide="18"] {
      -webkit-transform: translateX(calc(100vw * -18));
              transform: translateX(calc(100vw * -18)); }
    .projects-wrapper[data-active-slide="19"] {
      -webkit-transform: translateX(calc(100vw * -19));
              transform: translateX(calc(100vw * -19)); }
    .projects-wrapper[data-active-slide="20"] {
      -webkit-transform: translateX(calc(100vw * -20));
              transform: translateX(calc(100vw * -20)); }
    .projects-wrapper[data-active-slide="21"] {
      -webkit-transform: translateX(calc(100vw * -21));
              transform: translateX(calc(100vw * -21)); }
    .projects-wrapper[data-active-slide="22"] {
      -webkit-transform: translateX(calc(100vw * -22));
              transform: translateX(calc(100vw * -22)); }
    .projects-wrapper[data-active-slide="23"] {
      -webkit-transform: translateX(calc(100vw * -23));
              transform: translateX(calc(100vw * -23)); }
    .projects-wrapper[data-active-slide="24"] {
      -webkit-transform: translateX(calc(100vw * -24));
              transform: translateX(calc(100vw * -24)); }
    .projects-wrapper[data-active-slide="25"] {
      -webkit-transform: translateX(calc(100vw * -25));
              transform: translateX(calc(100vw * -25)); }
    .projects-wrapper[data-active-slide="26"] {
      -webkit-transform: translateX(calc(100vw * -26));
              transform: translateX(calc(100vw * -26)); }
    .projects-wrapper[data-active-slide="27"] {
      -webkit-transform: translateX(calc(100vw * -27));
              transform: translateX(calc(100vw * -27)); }
    .projects-wrapper[data-active-slide="28"] {
      -webkit-transform: translateX(calc(100vw * -28));
              transform: translateX(calc(100vw * -28)); }
    .projects-wrapper[data-active-slide="29"] {
      -webkit-transform: translateX(calc(100vw * -29));
              transform: translateX(calc(100vw * -29)); }
    .projects-wrapper[data-active-slide="30"] {
      -webkit-transform: translateX(calc(100vw * -30));
              transform: translateX(calc(100vw * -30)); }
    .projects-wrapper[data-active-slide="31"] {
      -webkit-transform: translateX(calc(100vw * -31));
              transform: translateX(calc(100vw * -31)); }
    .projects-wrapper[data-active-slide="32"] {
      -webkit-transform: translateX(calc(100vw * -32));
              transform: translateX(calc(100vw * -32)); }
    .projects-wrapper[data-active-slide="33"] {
      -webkit-transform: translateX(calc(100vw * -33));
              transform: translateX(calc(100vw * -33)); }
    .projects-wrapper[data-active-slide="34"] {
      -webkit-transform: translateX(calc(100vw * -34));
              transform: translateX(calc(100vw * -34)); }
    .projects-wrapper[data-active-slide="35"] {
      -webkit-transform: translateX(calc(100vw * -35));
              transform: translateX(calc(100vw * -35)); }
    .projects-wrapper[data-active-slide="36"] {
      -webkit-transform: translateX(calc(100vw * -36));
              transform: translateX(calc(100vw * -36)); }
    .projects-wrapper[data-active-slide="37"] {
      -webkit-transform: translateX(calc(100vw * -37));
              transform: translateX(calc(100vw * -37)); }
    .projects-wrapper[data-active-slide="38"] {
      -webkit-transform: translateX(calc(100vw * -38));
              transform: translateX(calc(100vw * -38)); }
    .projects-wrapper[data-active-slide="39"] {
      -webkit-transform: translateX(calc(100vw * -39));
              transform: translateX(calc(100vw * -39)); }
    .projects-wrapper[data-active-slide="40"] {
      -webkit-transform: translateX(calc(100vw * -40));
              transform: translateX(calc(100vw * -40)); }
    .projects-wrapper[data-active-slide="41"] {
      -webkit-transform: translateX(calc(100vw * -41));
              transform: translateX(calc(100vw * -41)); }
    .projects-wrapper[data-active-slide="42"] {
      -webkit-transform: translateX(calc(100vw * -42));
              transform: translateX(calc(100vw * -42)); }
    .projects-wrapper[data-active-slide="43"] {
      -webkit-transform: translateX(calc(100vw * -43));
              transform: translateX(calc(100vw * -43)); }
    .projects-wrapper[data-active-slide="44"] {
      -webkit-transform: translateX(calc(100vw * -44));
              transform: translateX(calc(100vw * -44)); }
    .projects-wrapper[data-active-slide="45"] {
      -webkit-transform: translateX(calc(100vw * -45));
              transform: translateX(calc(100vw * -45)); }
    .projects-wrapper[data-active-slide="46"] {
      -webkit-transform: translateX(calc(100vw * -46));
              transform: translateX(calc(100vw * -46)); }
    .projects-wrapper[data-active-slide="47"] {
      -webkit-transform: translateX(calc(100vw * -47));
              transform: translateX(calc(100vw * -47)); }
    .projects-wrapper[data-active-slide="48"] {
      -webkit-transform: translateX(calc(100vw * -48));
              transform: translateX(calc(100vw * -48)); }
    .projects-wrapper[data-active-slide="49"] {
      -webkit-transform: translateX(calc(100vw * -49));
              transform: translateX(calc(100vw * -49)); }
    .projects-wrapper[data-active-slide="50"] {
      -webkit-transform: translateX(calc(100vw * -50));
              transform: translateX(calc(100vw * -50)); }
    .projects-wrapper[data-active-slide="51"] {
      -webkit-transform: translateX(calc(100vw * -51));
              transform: translateX(calc(100vw * -51)); }
    .projects-wrapper[data-active-slide="52"] {
      -webkit-transform: translateX(calc(100vw * -52));
              transform: translateX(calc(100vw * -52)); }
    .projects-wrapper[data-active-slide="53"] {
      -webkit-transform: translateX(calc(100vw * -53));
              transform: translateX(calc(100vw * -53)); }
    .projects-wrapper[data-active-slide="54"] {
      -webkit-transform: translateX(calc(100vw * -54));
              transform: translateX(calc(100vw * -54)); }
    .projects-wrapper[data-active-slide="55"] {
      -webkit-transform: translateX(calc(100vw * -55));
              transform: translateX(calc(100vw * -55)); }
    .projects-wrapper[data-active-slide="56"] {
      -webkit-transform: translateX(calc(100vw * -56));
              transform: translateX(calc(100vw * -56)); }
    .projects-wrapper[data-active-slide="57"] {
      -webkit-transform: translateX(calc(100vw * -57));
              transform: translateX(calc(100vw * -57)); }
    .projects-wrapper[data-active-slide="58"] {
      -webkit-transform: translateX(calc(100vw * -58));
              transform: translateX(calc(100vw * -58)); }
    .projects-wrapper[data-active-slide="59"] {
      -webkit-transform: translateX(calc(100vw * -59));
              transform: translateX(calc(100vw * -59)); }
    .projects-wrapper[data-active-slide="60"] {
      -webkit-transform: translateX(calc(100vw * -60));
              transform: translateX(calc(100vw * -60)); } }
  .projects-wrapper .projects-container {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 100vw); }
    @media print, screen and (min-width: 64em) {
      .projects-wrapper .projects-container {
        padding: 50px calc((100vw - 1186px) / 2) 0;
        display: inherit;
        width: 100vw; }
        .projects-wrapper .projects-container:not(:nth-child(1)) {
          position: absolute;
          top: 0;
          left: 0; } }
    .projects-wrapper .projects-container .project {
      -webkit-transition: all 250ms ease;
      transition: all 250ms ease;
      width: 481px;
      height: 641px;
      max-width: calc(100vw - 20px);
      max-height: calc((100vw - 20px) / 481 * 641);
      position: relative;
      box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.58);
      overflow: hidden;
      margin: 0 auto; }
      @media print, screen and (min-width: 64em) {
        .projects-wrapper .projects-container .project {
          margin: 0;
          margin: initial; }
          .projects-wrapper .projects-container .project:nth-child(1) {
            -webkit-transition-delay: .6s;
                    transition-delay: .6s; }
          .projects-wrapper .projects-container .project:nth-child(2) {
            -webkit-transition-delay: .7s;
                    transition-delay: .7s; }
          .projects-wrapper .projects-container .project:nth-child(3) {
            -webkit-transition-delay: .8s;
                    transition-delay: .8s; }
          .projects-wrapper .projects-container .project:nth-child(even) {
            margin-left: auto; }
          .projects-wrapper .projects-container .project:not(:nth-child(3)) {
            margin-bottom: calc(641px / 3 * -1); } }
      .projects-wrapper .projects-container .project .image-wrapper {
        width: 100%;
        height: 100%;
        -webkit-transform-origin: center center;
                transform-origin: center center;
        -webkit-transition: -webkit-transform 60ms ease;
        transition: -webkit-transform 60ms ease;
        transition: transform 60ms ease;
        transition: transform 60ms ease, -webkit-transform 60ms ease; }
        .projects-wrapper .projects-container .project .image-wrapper img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          -webkit-filter: saturate(1.3);
                  filter: saturate(1.3);
          -webkit-transition: -webkit-transform 250ms ease;
          transition: -webkit-transform 250ms ease;
          transition: transform 250ms ease;
          transition: transform 250ms ease, -webkit-transform 250ms ease; }
      .projects-wrapper .projects-container .project .on-hover {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        color: white;
        background: rgba(239, 116, 0, 0.8);
        padding: 25px;
        opacity: 0;
        -webkit-transition: opacity 250ms ease;
        transition: opacity 250ms ease;
        z-index: 10; }
        .projects-wrapper .projects-container .project .on-hover .button {
          position: absolute;
          right: 25px;
          bottom: 25px;
          text-transform: uppercase;
          -webkit-transform: scale(0);
                  transform: scale(0);
          opacity: 0;
          -webkit-transition: opacity 250ms ease, -webkit-transform 0s ease;
          transition: opacity 250ms ease, -webkit-transform 0s ease;
          transition: transform 0s ease, opacity 250ms ease;
          transition: transform 0s ease, opacity 250ms ease, -webkit-transform 0s ease; }
      @media screen and (max-width: 63.99875em) {
        .projects-wrapper .projects-container .project.tapped img {
          -webkit-transform: scale(1.2) !important;
                  transform: scale(1.2) !important; }
        .projects-wrapper .projects-container .project.tapped .on-hover {
          opacity: 1; }
          .projects-wrapper .projects-container .project.tapped .on-hover .button {
            opacity: 1;
            -webkit-transform: scale(1);
                    transform: scale(1);
            -webkit-transition-delay: 1s;
                    transition-delay: 1s; } }
      @media print, screen and (min-width: 64em) {
        .projects-wrapper .projects-container .project:hover img {
          -webkit-transform: scale(1.2) !important;
                  transform: scale(1.2) !important; }
        .projects-wrapper .projects-container .project:hover .on-hover {
          opacity: 1; }
          .projects-wrapper .projects-container .project:hover .on-hover .button {
            opacity: 1;
            -webkit-transform: scale(1);
                    transform: scale(1);
            -webkit-transition-delay: 1s;
                    transition-delay: 1s; } }
    @media print, screen and (min-width: 64em) {
      .projects-wrapper .projects-container:not(.show) {
        z-index: 0; }
        .projects-wrapper .projects-container:not(.show) .project {
          opacity: 0;
          -webkit-transform: translateX(10%);
                  transform: translateX(10%); }
          .projects-wrapper .projects-container:not(.show) .project:nth-child(1) {
            -webkit-transition-delay: 0.3s;
                    transition-delay: 0.3s; }
          .projects-wrapper .projects-container:not(.show) .project:nth-child(2) {
            -webkit-transition-delay: 0.1s;
                    transition-delay: 0.1s; }
          .projects-wrapper .projects-container:not(.show) .project:nth-child(3) {
            -webkit-transition-delay: 0s;
                    transition-delay: 0s; } }

@media print, screen and (min-width: 64em) {
  .projects-toggle-wrapper {
    width: 1186px;
    max-width: 90vw;
    margin: 0 auto;
    z-index: 2;
    position: relative; } }

.projects-toggle-wrapper button {
  font-size: 16px;
  text-align: center;
  -webkit-transition: opacity 250ms ease;
  transition: opacity 250ms ease; }
  .projects-toggle-wrapper button:nth-child(2) {
    margin-top: 20px; }
  @media print, screen and (min-width: 64em) {
    .projects-toggle-wrapper button {
      margin-left: auto;
      -webkit-transform: translate(calc((481px / 2 * -1) + 50%), calc( 641px / 3 * -1));
              transform: translate(calc((481px / 2 * -1) + 50%), calc( 641px / 3 * -1)); } }
  .projects-toggle-wrapper button:not(.show) {
    opacity: 0;
    cursor: inherit; }

.medium-screens-swipe-indicator {
  margin-top: 50px;
  text-align: center; }

.content-wrapper {
  position: relative;
  min-height: 100vh;
  background: white;
  margin-bottom: 150px; }
  .content-wrapper:not(.no-before-no-after):before, .content-wrapper:not(.no-before-no-after):after {
    background: white;
    content: "\00a0";
    width: 100vw;
    min-width: 1440px;
    left: 50%;
    height: 50px;
    position: absolute;
    z-index: -1; }
  .content-wrapper:not(.no-before-no-after):before {
    -webkit-transform: translateY(-100%) translateX(-50%);
            transform: translateY(-100%) translateX(-50%);
    -webkit-clip-path: polygon(100% 100%, 0 100%, 0 0);
            clip-path: polygon(100% 100%, 0 100%, 0 0); }
  .content-wrapper:not(.no-before-no-after):after {
    bottom: 0;
    -webkit-transform: translateY(100%) translateX(-50%);
            transform: translateY(100%) translateX(-50%);
    -webkit-clip-path: polygon(100% 100%, 100% 0, 0 0);
            clip-path: polygon(100% 100%, 100% 0, 0 0); }

.menu-button-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  padding: 5px;
  z-index: 10;
  overflow: hidden; }
  .menu-button-wrapper .menu-button {
    position: relative;
    height: 30px;
    width: 30px;
    display: inline-block; }
    .menu-button-wrapper .menu-button span {
      position: absolute;
      display: block;
      height: 12.5%;
      width: 100%;
      background: #ef7400;
      -webkit-transition: all 1000ms ease;
      transition: all 1000ms ease; }
      .menu-button-wrapper .menu-button span:before {
        content: "\00a0"; }
      .menu-button-wrapper .menu-button span:nth-child(1) {
        top: 0;
        -webkit-transform-origin: top left;
                transform-origin: top left; }
      .menu-button-wrapper .menu-button span:nth-child(2), .menu-button-wrapper .menu-button span:nth-child(3) {
        top: calc(50% - 6.25%);
        height: 12.5% / 2; }
      .menu-button-wrapper .menu-button span:nth-child(4) {
        bottom: 0;
        -webkit-transform-origin: top right;
                transform-origin: top right; }
  .menu-button-wrapper.open .menu-button span:nth-child(1) {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  .menu-button-wrapper.open .menu-button span:nth-child(2) {
    top: calc(50% - 6.25%);
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .menu-button-wrapper.open .menu-button span:nth-child(3) {
    bottom: calc(50% - 6.25%);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .menu-button-wrapper.open .menu-button span:nth-child(4) {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }

.menu-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 9;
  -webkit-transition: -webkit-transform 1000ms ease;
  transition: -webkit-transform 1000ms ease;
  transition: transform 1000ms ease;
  transition: transform 1000ms ease, -webkit-transform 1000ms ease;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  -webkit-transition-delay: 250ms;
          transition-delay: 250ms; }
  @media print, screen and (min-width: 40em) {
    .menu-wrapper {
      -webkit-transition-duration: 750ms;
              transition-duration: 750ms;
      -webkit-transition-delay: 375ms;
              transition-delay: 375ms; } }
  @media print, screen and (min-width: 64em) {
    .menu-wrapper {
      -webkit-transition-duration: 1000ms;
              transition-duration: 1000ms;
      -webkit-transition-delay: 500ms;
              transition-delay: 500ms; } }
  .menu-wrapper:before {
    content: "\00a0";
    position: inherit;
    width: inherit;
    height: inherit;
    background: #ef7400;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: scaleX(1) translateX(100%);
            transform: scaleX(1) translateX(100%);
    -webkit-transition: inherit;
    transition: inherit;
    -webkit-transition-duration: 375ms;
            transition-duration: 375ms; }
    @media print, screen and (min-width: 40em) {
      .menu-wrapper:before {
        -webkit-transition-duration: 562.5ms;
                transition-duration: 562.5ms; } }
    @media print, screen and (min-width: 64em) {
      .menu-wrapper:before {
        -webkit-transition-duration: 750ms;
                transition-duration: 750ms; } }
  .menu-wrapper .menu-container {
    text-align: center; }
    .menu-wrapper .menu-container h1 {
      font-size: 54px; }
    .menu-wrapper .menu-container ul {
      list-style: none;
      margin: 0;
      display: grid;
      grid-template-rows: repeat(3, calc((100vh - 200px) / 3));
      align-items: center;
      margin-top: -20px; }
      .menu-wrapper .menu-container ul li button {
        display: inline;
        color: #ea7200;
        font-size: 26px; }
  .menu-wrapper:not(.show) {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
    -webkit-transition-duration: 250ms;
            transition-duration: 250ms; }
    @media print, screen and (min-width: 40em) {
      .menu-wrapper:not(.show) {
        -webkit-transition-duration: 375ms;
                transition-duration: 375ms; } }
    @media print, screen and (min-width: 64em) {
      .menu-wrapper:not(.show) {
        -webkit-transition-duration: 500ms;
                transition-duration: 500ms; } }
    .menu-wrapper:not(.show):before {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
      -webkit-transition-delay: 500ms;
              transition-delay: 500ms;
      -webkit-transition-duration: 750ms;
              transition-duration: 750ms; }
      @media print, screen and (min-width: 40em) {
        .menu-wrapper:not(.show):before {
          -webkit-transition-delay: 750ms;
                  transition-delay: 750ms;
          -webkit-transition-duration: 1125ms;
                  transition-duration: 1125ms; } }
      @media print, screen and (min-width: 64em) {
        .menu-wrapper:not(.show):before {
          -webkit-transition-delay: 1000ms;
                  transition-delay: 1000ms;
          -webkit-transition-duration: 1500ms;
                  transition-duration: 1500ms; } }

.page-wrapper {
  max-width: 800px;
  margin: 0 auto; }
  @media screen and (max-width: 850px) {
    .page-wrapper {
      padding: 0 45px; } }
  .page-wrapper .page-title {
    text-align: center;
    padding: 10px 0;
    border-bottom: solid 1px #373737; }
  .page-wrapper h1, .page-wrapper h2, .page-wrapper h3, .page-wrapper h4, .page-wrapper h5 {
    font-weight: 800; }
  .page-wrapper .wp-block-preformatted {
    background: #373737;
    color: white;
    padding: 10px;
    border-radius: 3px;
    overflow: scroll; }

.footer-wrapper {
  background: #1e0f00;
  min-height: 200px;
  position: relative;
  margin-top: 110px;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  grid-row-gap: 25px;
  padding: 100px 20px 25px;
  justify-items: center; }
  @media print, screen and (min-width: 40em) {
    .footer-wrapper {
      padding: 125px 50px 25px;
      grid-template-columns: repeat(3, 1fr);
      text-align: left; } }
  .footer-wrapper:before {
    background: #373737;
    content: "\00a0";
    width: 100vw;
    min-width: 1440px;
    left: 50%;
    height: 50px;
    position: absolute;
    z-index: 0;
    top: -1px;
    -webkit-clip-path: polygon(100% 100%, 100% 0, 0 0);
            clip-path: polygon(100% 100%, 100% 0, 0 0);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .footer-wrapper div {
    width: 200px; }
    .footer-wrapper div h3 {
      font-size: 20px; }
    .footer-wrapper div ul {
      list-style: none;
      margin: 0;
      font-size: 14px;
      opacity: 0.8; }
      .footer-wrapper div ul li a {
        color: white; }

.contact-wrapper {
  position: relative;
  width: 90vw;
  max-width: 500px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .contact-wrapper .intro {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    min-height: 0; }
    .contact-wrapper .intro .loading-wrapper, .contact-wrapper .intro .done-wrapper, .contact-wrapper .intro .form-wrapper {
      overflow: hidden;
      max-height: 0;
      -webkit-transition: inherit;
      transition: inherit;
      -webkit-transition-delay: 1s;
              transition-delay: 1s; }
    .contact-wrapper .intro .form-wrapper {
      -webkit-transition-delay: 0s;
              transition-delay: 0s; }
    .contact-wrapper .intro .loading-wrapper, .contact-wrapper .intro .done-wrapper {
      position: absolute;
      top: 0; }
    .contact-wrapper .intro .loading-wrapper .loading-indicator {
      position: relative;
      height: 30px;
      width: 100%; }
      .contact-wrapper .intro .loading-wrapper .loading-indicator:before {
        position: absolute;
        content: "\00a0";
        border-radius: 10px;
        width: 100px;
        height: 10px;
        -webkit-transform-origin: center center;
                transform-origin: center center;
        border: solid 10px #ef7400;
        -webkit-animation-name: move-around;
                animation-name: move-around;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        animation-direction: alternate-reverse; }

@-webkit-keyframes move-around {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    width: 10px;
    right: 100%; }
  50% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    width: 100px; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 10px;
    right: 0; } }

@keyframes move-around {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    width: 10px;
    right: 100%; }
  50% {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    width: 100px; }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 10px;
    right: 0; } }
    .contact-wrapper .intro:not(.loading):not(.done) .form-wrapper {
      max-height: 533px; }
    .contact-wrapper .intro.loading, .contact-wrapper .intro.done {
      min-height: 100px; }
    .contact-wrapper .intro.loading .form-wrapper {
      max-height: 0; }
    .contact-wrapper .intro.loading .loading-wrapper {
      max-height: 200px; }
    .contact-wrapper .intro.done .loading-wrapper {
      -webkit-transition-delay: 0s;
              transition-delay: 0s; }
    .contact-wrapper .intro.done .done-wrapper {
      max-height: 200px; }


.field {
  -webkit-transition: margin 250ms ease;
  transition: margin 250ms ease;
  margin-top: 25px;
  position: relative; }
  .field:before {
    position: absolute;
    content: "\00a0";
    background: #cc4b37;
    width: 10px;
    height: 10px;
    left: -30px;
    top: 15px;
    -webkit-clip-path: polygon(100% 50%, 0 0, 0 100%);
            clip-path: polygon(100% 50%, 0 0, 0 100%);
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
    opacity: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease; }
  .field + .field {
    margin-top: 0; }
  .field.invalid:before {
    left: -15px;
    opacity: 1; }
  .field.invalid input {
    text-decoration: line-through; }
  .field .field-content input:not([type="button"]):not([type="submit"]), .field .field-content textarea {
    line-height: 39px;
    display: block;
    padding: 0;
    border: solid 1px #373737;
    border-radius: 3px;
    width: 100%;
    background-color: transparent;
    background-image: none !important;
    border-bottom: solid 3px #ef7400;
    color: white;
    font-size: 16px;
    box-shadow: none !important;
    border-top: none; }
    .field .field-content input:not([type="button"]):not([type="submit"]):focus, .field .field-content input:not([type="button"]):not([type="submit"]):not([value=""]):not([data-value=""]), .field .field-content textarea:focus, .field .field-content textarea:not([value=""]):not([data-value=""]) {
      outline: 0; }
      .field .field-content input:not([type="button"]):not([type="submit"]):focus + label, .field .field-content input:not([type="button"]):not([type="submit"]):not([value=""]):not([data-value=""]) + label, .field .field-content textarea:focus + label, .field .field-content textarea:not([value=""]):not([data-value=""]) + label {
        padding-left: 0;
        opacity: 0.6; }
      .field .field-content input:not([type="button"]):not([type="submit"]):focus:not(textarea) + label, .field .field-content input:not([type="button"]):not([type="submit"]):not([value=""]):not([data-value=""]):not(textarea) + label, .field .field-content textarea:focus:not(textarea) + label, .field .field-content textarea:not([value=""]):not([data-value=""]):not(textarea) + label {
        -webkit-transform: translateY(-230%) scale(0.8);
                transform: translateY(-230%) scale(0.8); }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            .field .field-content input:not([type="button"]):not([type="submit"]):focus:not(textarea) + label, .field .field-content input:not([type="button"]):not([type="submit"]):not([value=""]):not([data-value=""]):not(textarea) + label, .field .field-content textarea:focus:not(textarea) + label, .field .field-content textarea:not([value=""]):not([data-value=""]):not(textarea) + label {
              -webkit-transform: translateY(-260%) scale(0.8);
                      transform: translateY(-260%) scale(0.8); } } }
  .field .field-content textarea {
    resize: none;
    line-height: inherit;
    height: 39px;
    font-family: inherit;
    font-size: 12px;
    margin-top: 10px;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease; }
    .field .field-content textarea:focus, .field .field-content textarea:not([data-value=""]) {
      height: 100px; }
      .field .field-content textarea:focus + label, .field .field-content textarea:not([data-value=""]) + label {
        -webkit-transform: translateY(-120px) scale(0.8);
                transform: translateY(-120px) scale(0.8); }
  .field .field-content label {
    display: block;
    -webkit-transform: translateY(-34px);
            transform: translateY(-34px);
    font-size: 16px;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    -webkit-transform-origin: top left;
            transform-origin: top left; }
    .field .field-content label .required-indicator {
      position: relative;
      z-index: 1;
      margin-left: 5px;
      -webkit-transition: all 250ms ease;
      transition: all 250ms ease;
      display: inline-block; }
      .field .field-content label .required-indicator:not(.show) {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        opacity: 0; }
      .field .field-content label .required-indicator:before {
        color: #cc4b37;
        position: absolute;
        content: "*";
        border-radius: 50%;
        top: 0;
        left: 0;
        padding-top: 4px;
        line-height: 6px;
        overflow: hidden;
        text-align: center;
        width: 10px;
        z-index: -1;
        -webkit-transform: translateX(-1px) translateY(2px);
                transform: translateX(-1px) translateY(2px); }

