@import "../../resources/scss/settings";

.page-wrapper {
  max-width: 800px;
  margin: 0 auto;

  @media screen and (max-width: 850px) {
    padding: 0 45px;
  }

  .page-title {
    text-align: center;
    padding: 10px 0;
    border-bottom: solid 1px $black;
  }
  h1, h2, h3, h4, h5 {
    font-weight: 800;
  }

  .wp-block-preformatted {
    background: $black;
    color: $white;
    padding: 10px;
    border-radius: 3px;
    overflow: scroll;
  }
}