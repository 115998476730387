@import "settings";

button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }


  &:not(.button) {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;

    > * {
      display: inline-block;
      margin: 0 auto;
    }
  }
}
.button {
  line-height: 37px;
  padding: 0 20px;
  font-weight: 700;
  background: none;
  border: none;
  font-size: 18px;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;

  &.hollow {
    border-radius: 2px;
    border: solid 3px;

    &.white {
      border-color: $white;
      color: $white;
    }
    &.primary {
      border-color: map_get($foundation-palette, primary);
      color: map_get($foundation-palette, primary);
    }
    &.secondary {
      border-color: map_get($foundation-palette, secondary);
      color: map_get($foundation-palette, secondary);
    }
  }

  &:not(.hollow) {
    &.primary {
      background-color: map_get($foundation-palette, primary);
      color: $white;
    }
  }
}