@import "~foundation-sites/scss/util/util";
@import "../../resources/scss/settings";

.content-wrapper {
  position: relative;
  min-height: 100vh;
  background: $white;
  margin-bottom: 150px;

  &:not(.no-before-no-after) {
    &:before, &:after {
      background: $white;
      content: "\00a0";
      width: 100vw;
      min-width: 1440px;
      left: 50%;
      height: 50px;
      position: absolute;
      z-index: -1;
    }

    &:before {
      transform: translateY(-100%) translateX(-50%);
      clip-path: polygon(100% 100%, 0 100%, 0 0);
    }

    &:after {
      bottom: 0;
      transform: translateY(100%) translateX(-50%);
      clip-path: polygon(100% 100%, 100% 0, 0 0);
    }
  }

}