.sub-title {
  white-space: nowrap;
  font-size: 20px;

  span.we {
    height: 30px;
    display: inline-block;
  }
  span {
    line-height: inherit;
  }

  ul {
    display: block;
    position: relative;
    list-style: none;
    line-height: inherit;
    margin-left: 34px;
    margin-top: -30px;

    li {
      position: absolute;
      left: 0;
      top: 0;
      white-space: nowrap;
      transition: all 250ms ease;
      clip-path: inset(0 0 0 0);

      &:not(.active) {
        clip-path: inset(0 100% 0 0);
      }
      &.active {
        transition-delay: 500ms;
      }
    }
  }
}