@import "settings";

@import url('https://fonts.googleapis.com/css?family=Heebo:300,400,700&display=swap');

body {
  font-family: 'Heebo', sans-serif;
  color: $black;
  font-size: 16px;
}

@font-face {
  font-family: 'The Bold Font';
  src: url('../fonts/theboldfont.ttf') format('truetype');
  font-display: swap;
}
.bold-font, .bold-font * {
  font-family: 'The Bold Font', "Arial Black", sans-serif;
  font-weight: bold;
}
.primary {
  color: map_get($foundation-palette, primary);
}
.secondary {
  color: map_get($foundation-palette, secondary);
}
.white {
  color: $white;
}