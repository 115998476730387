@import "~foundation-sites/scss/util/util";
@import "../../../resources/scss/settings";

.projects-wrapper {
  position: relative;
  display: grid;

  @include breakpoint(large) {
    display: inherit;
  }

  @for $i from 1 through 60 {
    &[data-length="#{$i}"] {
      grid-template-columns: repeat($i, 300vw);
    }
  }
  @include breakpoint(medium down) {
    &:not(.is-dragging) {
      transition: transform 250ms ease;
    }

    @for $i from 1 through 60 {
      &[data-active-slide="#{$i}"] {
        transform: translateX(calc(100vw * -#{$i}));
      }
    }
  }


  .projects-container {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 100vw);

    @include breakpoint(large) {
      padding: 50px calc((100vw - 1186px) / 2) 0;
      display: inherit;
      width: 100vw;

      &:not(:nth-child(1)) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .project {
      transition: all 250ms ease;
      width: 481px;
      height: 641px;
      max-width: calc(100vw - 20px);
      max-height: calc((100vw - 20px) / 481 * 641);
      position: relative;
      box-shadow: 0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.58);
      overflow: hidden;
      margin: 0 auto;

      @include breakpoint(large) {
        margin: initial;

        &:nth-child(1) {
          transition-delay: .6s;
        }
        &:nth-child(2) {
          transition-delay: .7s;
        }
        &:nth-child(3) {
          transition-delay: .8s;
        }
        &:nth-child(even) {
          margin-left: auto;
        }
        &:not(:nth-child(3)) {
          margin-bottom: calc(641px / 3 * -1);
        }
      }

      .image-wrapper {
        width: 100%;
        height: 100%;
        transform-origin: center center;
        transition: transform 60ms ease;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          filter: saturate(1.3);
          transition: transform 250ms ease;
        }
      }

      .on-hover {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        color: white;
        background: transparentize(map_get($foundation-palette, primary), .2);
        padding: 25px;
        opacity: 0;
        transition: opacity 250ms ease;
        z-index: 10;

        .button {
          position: absolute;
          right: 25px;
          bottom: 25px;
          text-transform: uppercase;
          transform: scale(0);
          opacity: 0;
          transition: transform 0s ease, opacity 250ms ease;
        }
      }

      @mixin focus-project {
        img {
          transform: scale(1.2) !important;
        }
        .on-hover {
          opacity: 1;

          .button {
            opacity: 1;
            transform: scale(1);
            transition-delay: 1s;
          }
        }
      }

      @include breakpoint(medium down) {
        &.tapped {
          @include focus-project;
        }
      }
      @include breakpoint(large) {
        &:hover {
          @include focus-project;
        }
      }
    }
    @include breakpoint(large) {
      &:not(.show) {
        z-index: 0;

        .project {
          opacity: 0;
          transform: translateX(10%);

          &:nth-child(1) {
            transition-delay: 0.3s;
          }
          &:nth-child(2) {
            transition-delay: 0.1s;
          }
          &:nth-child(3) {
            transition-delay: 0s;
          }
        }
      }
    }
  }
}
.projects-toggle-wrapper {
  @include breakpoint(large) {
    width: 1186px;
    max-width: 90vw;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  button {
    font-size: 16px;
    text-align: center;
    transition: opacity 250ms ease;

    &:nth-child(2) {
      margin-top: 20px;
    }

    @include breakpoint(large) {
      margin-left: auto;
      transform: translate(calc((481px / 2 * -1) + 50%), calc( 641px / 3 * -1));
    }
    &:not(.show) {
      opacity: 0;
      cursor: inherit;
    }
  }
}
.medium-screens-swipe-indicator {
  margin-top: 50px;
  text-align: center;
}